export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [4],
		"/blog": [~5],
		"/blog/[slug]": [~6],
		"/guide": [7],
		"/guide/accnotfound": [8],
		"/guide/accphnotfound": [9],
		"/guide/phlogin": [10],
		"/guide/xlogin": [11],
		"/login": [12],
		"/manage": [13,[2]],
		"/manage/blog": [14,[2]],
		"/manage/blog/add": [16,[2]],
		"/manage/blog/[id]": [15,[2]],
		"/manage/email": [17,[2]],
		"/manage/tickets": [18,[2]],
		"/manage/tickets/[id]": [19,[2]],
		"/manage/users": [~20,[2]],
		"/manage/waitlist": [21,[2]],
		"/panel": [22,[3]],
		"/panel/accounts": [23,[3]],
		"/panel/accounts/add": [27,[3]],
		"/panel/accounts/[id]": [24,[3]],
		"/panel/accounts/[id]/finish": [25,[3]],
		"/panel/accounts/[id]/report": [26,[3]],
		"/panel/plans": [~28,[3]],
		"/panel/profile": [29,[3]],
		"/panel/referrals": [30,[3]],
		"/panel/tickets": [31,[3]],
		"/panel/tickets/add": [33,[3]],
		"/panel/tickets/[id]": [32,[3]],
		"/privacy": [34],
		"/signin": [35],
		"/signout": [36],
		"/terms": [37]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	

	reroute: (() => {})
};

export { default as root } from '../root.js';